import styled, { createGlobalStyle, css } from 'styled-components';

import { primary, light } from './colors';
import boldFont from '../assets/fonts/Avenir_Next_Cyr_W00_Bold.ttf';
import regularFont from '../assets/fonts/Avenir_Next_Cyr_W00_Regular.ttf';
import demiFont from '../assets/fonts/Avenir_Next_Cyr_W00_Demi.ttf';

const scrollBars = css`
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, .1);
      background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb {
      background-color: ${primary};
      border: 0px solid transparent;
      border-radius: 3px;
      background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:vertical {
    height: 48px;
  }
`;

export const Anchor = styled.a.attrs(props => ({
  target: props.blank ? '_blank' : '',
  rel: 'noopener norefferer',
}))`
  color: inherit !important;
  display: flex;
  text-decoration: none !important;
  cursor: pointer;
`;

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: AvenirNextCyrRegular;

    #console-mobile {
      display: none;
    }
  }

  #logo-small {
    opacity: 0;
    transition: opacity .25s ease;
  }

  @media (max-width: 425px) {
    section {
      padding: 26% 0 !important;
    }

    .how-it-works {
      margin-bottom: 200px !important;
    }

    .our-works {
      padding: 200px 0;
      height: auto !important;
      & > div {
        flex-direction: column !important;
        & > div {
          width: 100% !important;
        }
      }
    }
    
    .contributed {
      display: none;
    }

    #logo {
      width: 80%;
    }

    #small-logo {
      width: 126px;
    }

    #console {
      display: none;
    }

    .gatsby-image-wrapper {
      width: 75% !important;
    }

    #console-mobile {
      display: flex !important;
    }

    .contribution {
      width: 100% !important;
    }

    #step-label {
      position: absolute;
      transform: rotate(75deg) translate(25%, 225%);
      min-width: 100px;
    }
  }

  @media (min-width: 426px) {
    body {
      font-size: 10pt;
    }
  }

  @media (min-width: 769px) {
    body {
      font-size: 12pt;
    }
  }

  @media (min-width: 1024px) {
    body {
      font-size: 14pt;
    }
  }


  @font-face {
    font-family: "AvenirNextCyrBold";
    font-display: fallback;
    src: url(${boldFont});
  }

  @font-face {
    font-family: "AvenirNextCyrRegular";
    font-display: fallback;
    src: url(${regularFont});
  }

  @font-face {
    font-family: "AvenirNextCyrDemi";
    font-display: fallback;
    src: url(${demiFont});
  }

  ${scrollBars}
`;

export const Section = styled.section.attrs(({ name }) => ({ className: `section ${name}` }))`
  width: 100%;
  padding: 15% 0;
`;

export Flex from './flex';
export * from './typography';
export * as colors from './colors';
