import React, { useState } from 'react'
import anime from 'animejs'
import Fullpage from '@fullpage/react-fullpage'

import { Flex } from '../../styled';
import { Context } from '../../utils'
import {
  Home,
  OurWorks,
  HowItWorks,
  GetInTouch,
  WeContributed,
} from './Sections'

const Main = () => {
  const [isScrollable, toggleScroll] = useState(true);

  const contextValue = {
    isScrollable,
    toggleScroll,
  };

  return (
    <Flex fillSpace scroll>
      <Flex column width="100%">
        <Home />
        <HowItWorks />
        <OurWorks />
        <WeContributed />
        <GetInTouch />
      </Flex>
    </Flex>
  );
}

export default Main;
