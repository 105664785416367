import styled, { css } from 'styled-components';
import React from 'react';
import ReactDOM from 'react-dom';

const scrollBars = css`
`;

const ModalPortal = styled.div.attrs({ className: 'modal-portal' })`
  top: 0;
  width: calc(100% + 8px);
  min-height: 100%;
  max-height: 100%;
  z-index: 999;
  display: flex;
  cursor: pointer;
  position: fixed;
  pointer-events: none;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const portalRoot = typeof document !== `undefined` ? document.body : null

export default class Modal extends React.Component {
  constructor() {
    super()
    // Use a ternary operator to make sure that the document object is defined
    this.el = typeof document !== `undefined` ? document.createElement('div') : null
  }

  componentDidMount = () => {    
    portalRoot.appendChild(this.el);
  }

  componentWillUnmount = () => {
    portalRoot.removeChild(this.el);
  }

  render() {
    const { children, onClose } = this.props;

    // Check that this.el is not null before using ReactDOM.createPortal
    if (this.el) {
      return ReactDOM.createPortal(
        <ModalPortal onClick={() => onClose()}>
          {children}
        </ModalPortal>,
        this.el,
      )
    } else {
      return null;
    }

  }
}
