import React from 'react';
import { colors } from 'styled';

export default () => (
  <svg
    className="octicon octicon-star"
    viewBox="0 0 14 16"
    version="1.1"
    aria-hidden="true"
    fill={colors.primary}
    width="18px"
    height="18px"
  >
    <path
      fillRule="evenodd"
      d="M14 6l-4.9-.64L7 1 4.9 5.36 0 6l3.6 3.26L2.67 14 7 11.67 11.33 14l-.93-4.74L14 6z"
    />
  </svg>
);
