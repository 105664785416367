import styled, { css } from 'styled-components';
import { colors, Flex, Text } from 'styled';

export const Wrap = styled.div`
  max-width: 1400px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  zoom: 1;
`;

export const FlexWrap = styled.div`
  max-width: 1400px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  zoom: 1;
`;

export const ContentSide = styled.div`
  width: 60%;
`;

export const TechSide = styled.div`
  width: 40%;
`;

export const Logo = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transform: translate(1px, 1px);
`;

export const Header = styled.h1`
  font-size: 30px;
  margin: 0;
  line-height: 40px;
  font-family: AvenirNextCyrBold;
  color: ${colors.normal};
  span {
    display: block;
    font-size: 62px;
    line-height: 60px;
    color: ${colors.primary};
    margin-top: 15px;
  }
`;

export const TechnologyItem = styled.div.attrs({ className: 'tech-item' })`
  display: inline-block;
  margin: 0 16px 24px 0;
`;

export const SectionTitle = styled.div`
  h2 {
    font-size: 30px;
    color: ${colors.dark};
    margin: 0;
    font-family: AvenirNextCyrBold;
  }
`;

export const ProjectLabel = styled(Text.Demi)`
  position: relative;
  transition: color .3s ease;
  :hover {
    color: ${props => props.activeBg};
    text-decoration: underline;
    text-decoration-color: ${props => props.activeBg};
  }
`

export const StepsWrapper = styled.div`
  max-width: 70vw;
  overflow: hidden;
`;

export const Steps = styled.div`
  display: flex;
  height: 100%;
  transform: translateX(-${props => props.step * 100}%);
  transition: transform .7s ease-in-out;
`;

export const Step = styled.div`
  min-width: 100%;
`;


export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const StepLabel = styled.h1`
  font-family: AvenirNextCyrBold;
  font-size: 2em;
  margin-bottom: 0;
  color: ${colors.dark};
`;

export const StepDescription = styled.p`
  font-size: 1.1em;
  width: 75%;
`;

export const StepsNav = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  justify-content: space-between;
`;

export const StepLinks = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 20px;
`;

export const StepAnchor = styled.div`
  background-color: ${props => (props.active ? '#4263f7' : 'black')};
  transition: background-color .6s ease;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -140%) scale(${props => (props.active ? '1' : '0.5')});
  transition: transform .4s ease;
`;

export const StepsProgressBar = styled.div`
  background: black;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  top: -2px;
`;

export const Animatron = styled.div.attrs(props => ({ className: `animatron-${props.id}` }))`
  border-top: 8px dotted #4253f7;
  position: absolute;
  top: -2px;
  left: 8px;
`;

export const Grid = styled.div`
  display: flex;
  position: relative;
`;

export const StepLink = styled.div.attrs({ className: 'step-link' })`
  cursor: pointer;
  position: relative;
  font-size: 1em;
  color: ${props => (props.active ? colors.primary : colors.normal)};
  transition: color .3s ease, transform .3s ease;
  font-family: AvenirNextCyrDemi;
  :hover {
    transform: scale(1.1);
  }
  :last-child {
    > div:after {
      display: none;
    }
  }
`;

export const Contribution = styled(Flex)`

`
