import React, { useState, useCallback } from 'react';
import anime from 'animejs';
import { Section, Flex, Text } from 'styled';

import Typewriter from '../../Typewriter';
import * as S from './styled';

const steps = [

  {
    label: 'We Collect Information',
    name: 'Specs',
    description: `All we need from you is simply the technical specifications. You provide us with a free­form document entailing as much detail as possible, and you send it to us!`,
  },
  {
    label: 'We Talk Things Over',
    name: 'Discussion',
    description: `Nothing ever worked without discussion! That’s why in this step, we take time to sit down with you and pick your brain as to all the specifics. We clarify any questions and we clarify what you need so we can come up with the best, tailor­made service, for you.`,
  },
  {
    label: 'We Create Tasks and Estimate Them',
    name: 'Planning',
    description: `Time is of essence and that’s why on this stage, we estimate in hours how long will each task take, based on the approved document. That way you have a clear idea of when is everything going to be ready, and we have our deadline!`,
  },
  {
    label: 'We Get Down To Work',
    name: 'Development',
    description: `We will use an online management system like Trello, Jira or any program that might suit you better. For our backlog (created tasks) we will create the first sprint and start to work. During this time, you will be able to track the progress of each task and test the results on the DEV version.`,
  },
  {
    label: 'We Deliver You the Result',
    name: 'Deployment',
    description: `On this stage our work is complete and we’re ready to deploy your project.  We can provide you with the right suggestions or alternative as to where you could or should deploy it. We’ll help you decide between deploying it on a dedicated or your own server or we could use Amazon Infrastructure among other programs.`
  },
  {
    label: 'We Help to Upkeep The System',
    name: 'Support',
    description: `Lastly, we provide long-term support for every project so you can go on with new features or improve the codebase.`,
  },
];

const labels = [
  'Single-page application?',
  'Desktop application?',
  'Mobile application?',
  'Complex backend architecture?',
  'Enterprise dashboard?',
  'Cross-platform application?',
];

const HowItWorks = () => {
  const [step, setStep] = useState(0);
  const [labelIndex, setLabelIndex] = useState(0);

  const nextLabel = useCallback(() => {
    setLabelIndex((labelIndex + 1) % labels.length);
  }, [labelIndex]);

  return (
    <Section name="how-it-works" height="75vh">
      <Flex width="100%" justify="center">
        <Flex width="70%" spacing={24} column justify="center">
          <Flex justify="space-between" width="70vw">
            <Flex column spacing={16}>
              <Text.Bold size="1.5em">How It Works?</Text.Bold>
              <span>Let us give you the tour.</span>
            </Flex>
            <Flex id="console" column width="30%" spacing={16}>
              <Text.Bold size="1.5em">
                Do you need
              </Text.Bold>
              <Typewriter label={labels[labelIndex]} onFinish={() => nextLabel()} />
              <span>We got you covered.</span>
            </Flex>
          </Flex>
          <Flex width="70vw" style={{ overflow: 'hidden' }}>
            <S.Steps step={step}>
              {steps.map(({ label, description }) => (
                <Flex column style={{ minWidth: '100%' }}>
                  <Text.Bold size={1.5}>
                    {label}
                  </Text.Bold>
                  <S.StepDescription>
                    {description}
                  </S.StepDescription>
                </Flex>
              ))}
            </S.Steps>
          </Flex>
          <Flex width="100%" justify="space-between" padding="64px 0 0 8px">
            {steps.map(({ name }, index) => (
              <S.StepLink key={name} active={index <= step} onClick={() => setStep(index)}>
                <S.StepAnchor active={index <= step} />
                <div id="step-label">
                  <Text.Demi>
                    {name}
                  </Text.Demi>
                </div>
              </S.StepLink>
            ))}
          </Flex>
          <Flex
            id="console-mobile"
            column
            width="100%"
            spacing={16}
            align="flex-end"
            style={{ transform: 'translateY(150%)' }}
          >
            <Text.Bold size="1.5em">
              Do you need
            </Text.Bold>
            <Typewriter label={labels[labelIndex]} onFinish={() => nextLabel()} />
            <span>We got you covered.</span>
          </Flex>
        </Flex>
      </Flex>
    </Section>
  );
}

export default HowItWorks;
