import React from "react";

const Logo = props => (
  <svg width="176px" id="logo-small" viewBox="0 0 849 175" {...props}>
    <g fill="#1A1AFF" stroke="none" strokeWidth={1} fillRule="evenodd">
      <g transform="translate(400.67 14.9)" fillRule="nonzero">
        <path d="M0 0L17.2 0 33.96 48.42 51.05 0 61.46 0 78.66 48.42 95.41 0 112.72 0 83.47 82.16 73.72 82.16 56.33 33.85 38.91 82.16 29.16 82.16z" />
        <path d="M177.57 82.15L118.2 82.15 118.2 0 175.6 0 175.6 14.57 135.72 14.57 135.72 32.43 168.33 32.43 168.33 47.1 135.72 47.1 135.72 67.58 177.56 67.58z" />
        <path d="M245.92 58.16c0 12.27-8.32 24-26.07 24h-33.52V0h32.42c12.16 0 21.8 8.87 21.8 19.94a18.2 18.2 0 01-9.42 16.1c11.28 4.82 14.79 12.06 14.79 22.12zm-29-28.37c4.27-.55 6.13-3.94 6.13-7.67 0-4.05-1.86-7.56-6.46-7.56h-12.73v15.23h13.06zm11.5 26.18c0-6.24-3.72-11.39-10.41-11.61h-14.15v23.22h13.8c6.9 0 10.73-5.25 10.73-11.61h.03zM285.46 61.78h-14.35v20.37h-17.52V0h31.88c18.84 0 31.22 13.91 31.22 30.89s-12.36 30.89-31.23 30.89zm13.69-30.89c0-8.87-5.81-16.32-15-16.32h-13v32.64h13c9.18 0 15-7.34 15-16.32zM366.08 66.71h-32.64l-6.11 15.44h-17.2L342.75 0h14l32.64 82.16h-17.2l-6.11-15.45zm-5.91-14.61l-10.41-26-10.43 26h20.84z" />
        <path d="M448.02 82.15L396.02 82.15 396.02 0 413.55 0 413.55 67.59 448.06 67.59z" />
      </g>
      <path d="M202.68 174.757l-9.054-9.153L193.46 0h101.105l9.163 9.055V32.25H326.7l9.467 9.385v36.393H303.73v32.24h-64.49V174.7l-36.558.057zm-4.218-12.878h27.94V97.373h64.49v-27.94h-64.465v-36.55h64.49V4.943h-92.455V161.88zm96.722-124.637v27.932h27.94V37.242h-27.94zM239.24 64.49h50.96V45.778h-50.96V64.49zM0 0h36.55l9.22 9.055v55.438H64.48V0h36.864l8.915 9.055V64.49h18.72V0h36.632l9.137 9.055.033 133.404-36.772.025-9.03-9.006-.009-23.208h-23.029l-9.212-9.222V78.027H78.02v32.243l-32.25-.002v32.216H9.064L0 133.478V0zm4.985 4.993L4.952 129.68h28.006V97.225l32.24.198v-27.94h-32.24V4.993H4.985zm64.49 60.18l27.94-.049V5.018l-27.94.008v60.148zm32.281 4.326l-.032 27.924h32.26l-.012 32.24h27.932V4.993h-27.94v64.49l-32.208.016z" />
    </g>
  </svg>
);

export default Logo;

