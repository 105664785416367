import hostawayScreenshot1 from '../assets/screenshots/hostaway/1.png'
import hostawayScreenshot2 from '../assets/screenshots/hostaway/2.png'
import hostawayScreenshot3 from '../assets/screenshots/hostaway/3.png'
import hostawayScreenshot4 from '../assets/screenshots/hostaway/4.png'

const PORTFOLIO = [
  {
    title: 'Hostaway',
    description:
      'Property Management Software (PMS), which aim is to help vacation rental managers get more bookings and increase revenues with less hassle. Automated channel manager solves double-bookings, makes guest communication easier and lets you focus on your business. Whether you are renting out your spare bedroom or manage entire properties for others.',
    contribution:
      'We developed the MVP, and then proceeded to transform it into a full-scaled web application',
    url: 'https://hostaway.com',
    screenshots: [
      hostawayScreenshot1,
      hostawayScreenshot2,
      hostawayScreenshot3,
      hostawayScreenshot4,
    ],
  },
  {
    title: 'DeliverMD',
    description:
      'Out-of-box enterprise inventory, order and delivery management software solution for medical cannabis dispansaries.',
    contribution:
      'We developed frontend part as well as the backend, a mobile app and additionally, a global marketplace app.',
    url: 'https://appadvice.com/app/delivermd/1355710088',
    screenshots: [],
  },
  {
    title: 'ZenKraft',
    description:
      'Shipping service for manage and track shipments integrated with 50+ different shipping carriers.',
    contribution: 'We developed the backend and the frontend',
    url: 'https://zenkraft.com/',
    screenshots: [],
  },
  {
    title: 'GymCloud',
    description:
      'Cloud-based fitness programming Easy-to-use, cloud-based workout programming website and mobile app will help you improve client performance, increase revenue, and save time and money. With GymCloud you can create exercises, assign workouts, develop programs, and track exercise performance for your clients IN PERSON or REMOTELY.',
    contribution:
      "We've been part of gymcloud's IT team, worked on frontend and mobile app.",
    url: 'https://www.gymcloud.com/',
    screenshots: [],
  },
  {
    title: 'Mercedes-Benz',
    description: 'Online store',
    contribution:
      "We've been part of rockstardevelopers IT team, worked on frontend",
    url: 'https://www.online-store.mercedes-benz.de/',
    screenshots: [],
  },
  {
    title: 'RxData',
    description:
      'Platform for international pharma pricing and access data. The service are regularly collecting data from authoritative online sources from 15+ North American and Europen countries and incorporating that data into proprietary reporting platform.',
    contribution:
      'We developed the frontend part and were involved in backend as well.',
    url: 'http://www.rxdata.net/',
    screenshots: [],
  },
  {
    title: 'GreenKeyTech',
    description:
      'Is the voice-driven collaboration platform for teams: enhancing compliance and enabling sophisticated data analytics and workflows.',
    contribution:
      'We developed the frontend part and were involved in backend as well.',
    url: 'https://greenkeytech.com/',
    screenshots: [],
  },
  {
    title: 'DoctorDong',
    description: 'Financial service for Asian market.',
    contribution:
      "We've been part of DoctorDong's IT team, worked on backend part.",
    url: 'https://doctordong.vn/',
    screenshots: [],
  },
]

export default PORTFOLIO
