import React, { useEffect, useState, useCallback } from 'react'
import anime from 'animejs'
import { Anchor, Flex } from 'styled'

import Logo from '../Logo'
import * as S from './styled'
import Sidebar from './Sidebar'
import { Burger } from '../Icons'

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = useCallback(() => setSidebarOpen(!sidebarOpen), [
    sidebarOpen,
  ])

  return (
    <S.Header>
      <Flex padding="0 20px" spacing={32} fillSpace justify="space-between" align="center">
        <Logo />
        <Flex spacing={16} align="center">
          <Anchor blank href="https://github.com/web-pal">
            <S.Octocat />
          </Anchor>
          <Anchor href="#contact">
            <S.Button>Say Hi</S.Button>
          </Anchor>
        </Flex>
      </Flex>
    </S.Header>
  )
}

export default Header
