const PROJECTS = [
  {
    title: 'Instex',
    description:
      'Decentralized exchange for ERC20 tokens. Based on 0x protocol',
    url: 'https://instex.io',
    screenshots: [
      'https://miro.medium.com/max/2000/1*gI1FfHIpUoXS5R4OwyTSaQ.png',
      'https://miro.medium.com/max/2000/1*3d8y9Z_01QLiRta2GWk7Ww.png',
    ],
  },
  {
    title: 'Chronos Timetracker',
    description:
      'Desktop client for JIRA. Track time, upload automatic and manual worklogs without a hassle. Cross-platform. Open-sourced. Free.',
    url: 'https://chronos.web-pal.com',
    screenshots: [
      'https://chronos.web-pal.com/static/chronos-screenshot.eafbd89a.png',
    ],
  },
  {
    title: 'Chronos Timesheets',
    description:
      'JIRA plugin to view worklogs. Watch what your team is busy with, calculate salaries, view screenshots and activity levels. Rich and flexible.',
    url: 'https://chronos.web-pal.com',
    screenshots: [
      'https://chronos.web-pal.com/static/timeline-view.0d0e439f.png',
    ],
  },
  {
    title: 'Electronic Stories',
    description: '"Tell" your react-stories in Electron environment',
    url: 'https://github.com/web-pal/electronic-stories',
    screenshots: [
      'https://camo.githubusercontent.com/f9a7bc662ffcb2a6fbfdd26d322e2772c2439db7/68747470733a2f2f7765622d70616c2e636f6d2f6173736574732f696d616765732f656c656374726f6e69632d73746f726965732d636f7665722e706e67',
    ],
  },
  {
    title: 'DBGlass',
    description:
      'Cross-platform open-source desktop GUI client for managing databases.',
    url: 'https://dbglass.web-pal.com',
    screenshots: [
      'http://dbglass.web-pal.com/images/dbglass_screenshot.jpg',
      'http://dbglass.web-pal.com/images/dbglass_structure_screenshot.jpg',
      'http://dbglass.web-pal.com/images/dbglass_editor_screenshot.jpg',
    ],
  },
  {
    title: 'React Trello Board',
    description: 'A Trello like board based on React, Redux, React-dnd.',
    url: 'http://react-trello-board.web-pal.com/',
    screenshots: [
      'https://camo.githubusercontent.com/d7c0ad2a2b05dd321baccffaa7d71222c7b58954/68747470733a2f2f73332e616d617a6f6e6177732e636f6d2f72656163742d7472656c6c6f2f626f6172645f73637265656e2e706e67',
    ],
  },
]

export default PROJECTS
