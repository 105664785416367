import React from "react";

import { GlobalStyle } from '../styled';
import { Header, Main, Nav, SEO } from '../components';

export default () => (
  <>
    <Header />
    <Main />
    <SEO />
    <GlobalStyle />
  </>
);
