import styled from 'styled-components';
import * as colors from './colors';

export const Text = styled.span`
  font-family: 'AvenirNextCyrRegular';
  font-size: ${props => props.size || 1}em;
  color: ${props => props.color || 'inherit'};
  opacity: ${props => props.opacity || 1};
`;

Text.Bold = styled(Text)`
  font-family: 'AvenirNextCyrBold';
`;

Text.Demi = styled(Text)`
  font-family: 'AvenirNextCyrDemi';
`;
