import styled from 'styled-components'
import { colors } from 'styled'

import logo from '../../../assets/images/WebPal.svg'
import octocat from '../../../assets/images/octocat.svg'
import burger from '../../../assets/images/burger.svg'

export const Header = styled.header`
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 101;
  position: fixed;
`

export const HeaderTrianlge = styled.div`
  position: absolute;
  cursor: default;
  pointer-events: none;
`

export const Menu = styled.div`
  float: left;
  cursor: pointer;
  position: relative;
  z-index: 1;
  width: 164.3px;
`
export const Octocat = styled.img.attrs({ src: octocat })`
  width: 24px;
  background: white;
  borde-radius: 50%;
`

export const Button = styled.div`
  border: 4px solid #282828;
  cursor: pointer;
  font-size: 1.1em;
  color: #191919;
  font-family: AvenirNextCyrBold;
  text-align: center;
  padding: 5px 10px;
  display: inline-block;
  transition: all 0.3s easeInOutQuad;
  white-space: nowrap;
  background: white;
  :hover {
    background: ${colors.secondary};
    transform: scale(1.05);
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
`

export const Social = styled.div`
  float: right;
  display: flex;
  aling-items: flex-center;
`
