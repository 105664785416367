import React, { useEffect } from 'react'
import anime from 'animejs'
import { Section, Flex, Text, colors } from 'styled'

const GetInTouch = () => {
  useEffect(() => {
    anime({
      targets: '#hand',
      rotate: [-10, 10],
      translateX: [-5, 5],
      duration: 1200,
      easing: 'easeInOutQuad',
      direction: 'alternate',
      loop: true,
    })
  }, [])
  return (
    <Section name="get-in-touch">
      <Flex
        column
        align="center"
        spacing={16}
        justify="center"
        fillSpace
        padding="120px 20%"
      >
        <Text.Demi size={1.75}>
          Say hello{' '}
          <div id="hand" style={{ fontSize: '1.2em', display: 'inline-block' }}>
            👋
          </div>
        </Text.Demi>
        <a
          style={{
            color: colors.primary,
          }}
          href="mailto:it@web-pal.com?subject=Hello Web-Pal!&body=So, I need a web application, maybe you guys can help?&cc=mail.vpal@gmail.com, gloosx@gmail.com"
        >
          <Text.Bold size={2.5} style={{ whiteSpace: 'nowrap' }}>
            it@web-pal.com
          </Text.Bold>
        </a>
      </Flex>
    </Section>
  )
}

export default GetInTouch
