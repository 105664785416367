import styled from 'styled-components'
import { colors, Flex } from 'styled'

export const ProjectLink = styled.span`
  fill: ${props => props.fill};
  cursor: pointer;
  transition: transform 0.3s ease;
  :hover {
    transform: scale(1.1);
  }
`

export const ModalCard = styled(Flex).attrs({ className: 'modal-card' })`
  position: absolute;
  background: ${({ styling }) =>
    styling === 'dark' ? colors.primary : colors.secondary};
  div > span {
    color: ${({ styling }) => (styling === 'dark' ? 'white' : colors.dark)};
    &:not:first-child{

    }
  }
  bottom: 0;
  width: 85vw;
  border-radius: 4px;
  height: 100%;
  transform: translateY(100%);
  box-shadow: 0 0 32px 0px rgba(0, 0, 0, 0.1);
`
