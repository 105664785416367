import React from 'react'
import { Flex, Text, Anchor, colors } from 'styled'

import * as S from './styled'
import { Link } from '../Icons'

const ModalCard = ({ project, styling }) => (
  <S.ModalCard onClick={e => e.stopPropagation()} styling={styling}>
    {project && (
      <Flex column spacing={32} fillSpace padding="24px">
        <Flex width="100%" align="center" justify="space-between">
          <Text.Bold size={3} opacity={0.9}>
            {project.title}
          </Text.Bold>
          <Anchor blank href={project.url}>
            <S.ProjectLink fill={styling === 'dark' ? 'white' : colors.dark}>
              <Link />
            </S.ProjectLink>
          </Anchor>
        </Flex>
        <Flex column spacing={8} width="80%">
          <Text.Bold size={1.5} opacity={0.5}>
            ABOUT
          </Text.Bold>
          <Text.Demi size={1.2}>{project.description}</Text.Demi>
        </Flex>
        {project.contribution && (
          <Flex column spacing={8} width="80%">
            <Text.Bold size={1.5} opacity={0.5}>
              OUR CONTRIBUTION
            </Text.Bold>
            <Text.Demi size={1.2}>{project.contribution}</Text.Demi>
          </Flex>
        )}
        <Flex column spacing={12} width="100%">
          <Text.Bold size={1.5} opacity={0.5}>
            ATTACHMENTS
          </Text.Bold>
          <Flex spacing={16}>
            {project.screenshots.map((s, i) => (
              <Anchor blank href={s} key={i} style={{ width: 'calc(25% - 16px)' }}>
                <img
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  src={s}
                  alt="screenshot"
                />
              </Anchor>
            ))}
          </Flex>
        </Flex>
      </Flex>
    )}
  </S.ModalCard>
)

export default ModalCard
