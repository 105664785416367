import React, { useCallback, useState } from 'react'
import anime from 'animejs'
import { Flex, Text, Section, colors } from 'styled'

import * as S from './styled'
import Modal from '../../Modal'
import ModalCard from '../../ModalCard'
import { PROJECTS, PORTFOLIO } from '../../../utils'

const OurWorks = () => {
  const [modalStyle, setModalStyle] = useState('light')
  const [selectedProject, setSelectedProject] = useState()
  const raiseCard = useCallback(() => {
    document.querySelector('.modal-portal').style.pointerEvents = 'all'

    var tl = anime.timeline({
      easing: 'easeOutExpo',
      duration: 350,
    })

    tl.add({
      targets: ['#gatsby-focus-wrapper', 'header'],
      filter: 'blur(4px)',
    })
      .add({
        targets: '.modal-card',
        easing: 'easeInOutExpo',
        translateY: ['100%', '15%'],
      })
      .add({
        targets: '.modal-card',
        easing: 'easeInOutExpo',
        boxShadow: '0 0 32x 0 rgba(0, 0, 0, .1)',
      })
  }, [])

  const closeCard = useCallback(() => {
    var tl = anime.timeline({
      easing: 'easeOutExpo',
      duration: 250,
    })

    document.querySelector('.modal-portal').style.pointerEvents = 'none'

    tl.add({
      targets: ['#gatsby-focus-wrapper', 'header'],
      filter: 'blur(0px)',
    }).add({
      targets: '.modal-card',
      easing: 'easeInOutExpo',
      translateY: ['20%', '100%'],
      boxShadow: '0 0 0px 0 rgba(0, 0, 0, .1)',
    })
  }, [])
  return (
    <Section name="our-works" height="75vh">
      <Flex width="100%" spacing={32} height="100%" justify="center">
        <Flex column width="40%" align="center">
          <Text.Bold size={1.75}>Projects</Text.Bold>
          <Flex column width="100%" align="center" padding="2em 0" spacing={12}>
            {PROJECTS.map(project => (
              <S.ProjectLabel
                key={project.title}
                color={colors.primary}
                activeBg={colors.secondary}
                size={1.5}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedProject(project)
                  setModalStyle('dark')
                  raiseCard()
                }}
              >
                {project.title}
              </S.ProjectLabel>
            ))}
          </Flex>
        </Flex>
        <Flex column width="40%" align="center">
          <Text.Bold size={1.75}>Portfolio</Text.Bold>
          <Flex column width="100%" align="center" padding="2em 0" spacing={12}>
            {PORTFOLIO.map(project => (
              <S.ProjectLabel
                key={project.title}
                color={colors.secondary}
                size={1.5}
                style={{ cursor: 'pointer' }}
                activeBg={colors.primary}
                onClick={() => {
                  setSelectedProject(project)
                  setModalStyle('light')
                  raiseCard()
                }}
              >
                {project.title}
              </S.ProjectLabel>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Modal onClose={() => closeCard()}>
        <ModalCard project={selectedProject} styling={modalStyle} />
      </Modal>
    </Section>
  )
}

export default OurWorks
