import React from 'react';

const initialState = {
    isScrollable: true,
    toggleScroll: () => {},
};

const Context = React.createContext(initialState);

export default Context;
