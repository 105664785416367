import React, { useEffect, useState } from 'react'
import anime from 'animejs'
import { Flex, Text, Anchor, colors } from 'styled'

const items = [
  {
    label: 'Home',
    hash: '#who',
  },
  {
    label: 'How?',
    hash: '#how',
  },
  {
    label: 'Portfolio',
    hash: '#what',
  },
  {
    label: 'Open source',
    hash: '#open-source',
  },
  {
    label: 'Contact',
    hash: '#contact',
  },
]
const applePayMethod = {
  supportedMethods: 'basic-card',
  data: {
    supportedNetworks: ['mastercard', 'visa'],
  },
}
const paymentDetails = {
  total: {
    label: 'My Merchant',
    amount: { value: '27.50', currency: 'USD' },
  },
  displayItems: [
    {
      label: 'Tax',
      amount: { value: '2.50', currency: 'USD' },
    },
    {
      label: 'Ground Shipping',
      amount: { value: '5.00', currency: 'USD' },
    },
  ],
}

const Sidebar = ({ isOpen, onClose }) => {
  const [display, setDisplay] = useState('none')
  const applePayButtonClicked = async () => {
    // Consider falling back to Apple Pay JS if Payment Request is not available.
    if (!window.PaymentRequest) return
    try {
      const request = new PaymentRequest([applePayMethod], paymentDetails)
      console.log(request)
      // request.onmerchantvalidation = function(event) {
      //   // Have your server fetch a payment session from event.validationURL.
      //   const sessionPromise = fetchPaymentSession(event.validationURL)
      //   event.complete(sessionPromise)
      // }

      // request.onshippingoptionchange = function(event) {
      //   // Compute new payment details based on the selected shipping option.
      //   const detailsUpdatePromise = computeDetails()
      //   event.updateWith(detailsUpdatePromise)
      // }

      // request.onshippingaddresschange = function(event) {
      //   // Compute new payment details based on the selected shipping address.
      //   const detailsUpdatePromise = computeDetails()
      //   event.updateWith(detailsUpdatePromise)
      // }

      const response = await request.show()
      console.log(response)
      // const status = processResponse(response)
      // console.log(status)
      // response.complete(status)
    } catch (e) {
      // Handle errors
    }
  }

  // TODO: What is it?
  useEffect(() => {
    setTimeout(() => setDisplay('block'), 500)
  }, [])

  useEffect(() => {
    const tl = anime.timeline({
      easing: 'easeInOutExpo',
      duration: 350,
    })
    const { filter, translateX } = isOpen
      ? { filter: 'blur(4px)', translateX: '0' }
      : { filter: 'blur(0px)', translateX: '-300' }

    tl.add({
      filter,
      targets: ['.gatsby-focus-wrapper'],
    }).add({
      translateX,
      targets: '.sidebar',
    })
  }, [isOpen])

  const currentHash =
    typeof window !== 'undefined' ? window.location.hash : ''

  return (
    <Flex
      onClick={onClose}
      style={{
        display,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        cursor: 'pointer',
        position: 'fixed',
        pointerEvents: isOpen ? 'all' : 'none',
      }}
    >
      <Flex
        column
        width="300px"
        spacing={16}
        padding="16px"
        height="100vh"
        className="sidebar"
        style={{
          cursor: 'default',
          position: 'absolute',
        }}
        background={colors.primary}
        onMouseLeave={onClose}
      >
        {items.map(({ label, hash }) => (
          <Anchor onClick={onClose} key={label} href={hash}>
            <Text.Bold
              size={2}
              color={currentHash.includes(hash) ? colors.secondary : 'white'}
            >
              {label}
            </Text.Bold>
          </Anchor>
        ))}
      </Flex>
    </Flex>
  )
}

export default Sidebar
