import React, { useState, useEffect } from 'react'
import anime from 'animejs'
import { Text, colors } from 'styled'

const Typewriter = ({ label, onFinish }) => {
  const [end, setEnd] = useState(0)

  const animString = {
    substring: 0,
  }

  useEffect(
    () => {
      anime({
        targets: '#underscore',
        opacity: 0,
        loop: true,
        direction: 'alternate',
        easing: 'easeInOutExpo',
        duration: 600,
      });
    }, []);

  useEffect(() => {
    setEnd(0)

    const anim = anime({
      targets: animString,
      substring: label.length,
      direction: 'alternate',
      easing: 'easeInOutCubic',
      duration: anime.random(1750, 3000),
      endDelay: 1000,
      round: 1,
      update: ({ animations }) => {
        const step = animations[0].currentValue

        setEnd(step)
      },
    })

    anim.finished.then(() => {
      onFinish()
    })
  }, [label])

  return (
    <Text.Bold size={1.3} id="typewriter">
      {label.substring(0, end)}
      <Text.Demi id="underscore" color={colors.primary}>
        _
      </Text.Demi>
    </Text.Bold>
  )
}

export default Typewriter
