import React, { useEffect, useState } from 'react';
import anime from 'animejs';
import { Flex, Text, Section, Anchor, colors } from 'styled';

import * as S from './styled';
import { Star, Fork } from '../../Icons';


const WeContributed = () => {
  const [repos, setRepos] = useState([]);

  useEffect(() => {
    fetch('https://s3.amazonaws.com/webpal-landing/weContributed.json')
      .then(response => (response.json())
        .then((repos) => {
          setRepos(repos);
        }));

    anime({
      targets: '#heart',
      scale: [0.9, 1.1],
      duration: 900,
      direction: 'alternate',
      loop: true,
      easing: 'easeInExpo',
    });

  }, []);

  return (
    <Section name="contributed">
      <Flex column fillSpace spacing={128} padding="90px 0 0 0" align="center" justify="center">
        <Flex column align="center">
          <Flex spacing={8}>
            <Text.Bold size={2}>
              We
            </Text.Bold>
            <div id="heart" style={{ fontSize: '2em' }}>❤️</div>
            <Text.Bold size={2}>
              Open Source
            </Text.Bold>
          </Flex>
        </Flex>
        <Flex wrap width="80%" spacing={22}>
          {repos.map(r => (
            <Anchor className="contribution" key={r.id} blank href={r.html_url} style={{ width: 'calc(50% - 22px)' }}>
              <S.Contribution
                clickable
                width="100%"
                borderBottom="1px solid rgba(0, 0, 0, .1)"
                height="100px"
              >
                <Flex spacing={24} justify="space-between" width="100%">
                  <Flex column spacing={8} width="70%">
                    <Text.Demi color={colors.primary}>
                      {r.full_name}
                    </Text.Demi>
                    <Text>
                      {r.description}
                    </Text>
                  </Flex>
                  <Flex column width="30%" spacing={8}>
                    <Flex background="rgba(0, 0, 0, .05)" borderRadius="14px" padding="4px 8px" spacing={8} align="center" width="50%">
                      <Fork />
                      <Text size={0.9}>
                        {r.forks}
                      </Text>
                    </Flex>
                    <Flex background="rgba(0, 0, 0, .05)" borderRadius="14px" padding="4px 8px" spacing={8} align="center" width="50%">
                      <Star />
                      <Text size={0.9}>
                        {r.stargazers_count}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </S.Contribution>
            </Anchor>
          ))}
        </Flex>

      </Flex>
    </Section>
  );
};

export default WeContributed;
